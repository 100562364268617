import { useUpdateLocationHoursQuery } from "opsapi/hooks/operations/locations";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui";
import Loader from "../../../ui/components/Loader/Loader";
import ToastContext from "../../../utils/contexts/ToastContext";
import useCurrentLocation from "../../../utils/hooks/useCurrentLocation";
import { StoreHoursForm } from "../StoreHoursForm";
import { LocationHoursConfirmationModal } from "./LocationHoursConfirmationModal";
interface Props {
  stores: any;
}

export const LocationHours = ({ stores }: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { showToast } = useContext(ToastContext);
  const { currentLocation, loading } = useCurrentLocation();

  const form = useForm();
  const { handleSubmit } = form;

  const { mutate: updateLocation } = useUpdateLocationHoursQuery({
    params: { path: { slug: currentLocation.slug } },
    reactQuery: {
      onSuccess: () => {
        showToast({
          description: `Submitted successfully`,
          variant: "success",
          seconds: 2,
        });
      },
      onError: (error) => {
        showToast({
          description: error.message,
          variant: "error",
          seconds: 10,
        });
      },
    },
  });

  const onSubmitHandler = async (data: any) => {
    // Create id mapping
    const storeIDs = stores.map((item: any) => item.id);
    await updateLocation({
      store_ids: storeIDs,
      store_hours: data.store_hours.map((item: any) => ({
        hours: item.hours.map((item: any) => ({
          start_day: 0,
          end_day:
            parseInt(item.end_time.split(":")[0]) <
            parseInt(item.start_time.split(":")[0])
              ? 1
              : 0,
          start_time: item.start_time,
          end_time: item.end_time,
        })),
        day_of_week: parseInt(item.day_of_week),
      })),
    });
    setShowModal(false);
  };

  if (stores.length === 0) {
    return <p>No stores found</p>;
  }

  if (loading) {
    return <Loader loading />;
  }

  return (
    <>
      <StoreHoursForm form={form} />
      <Button onClick={() => setShowModal(true)}>Update</Button>

      <LocationHoursConfirmationModal
        showModal={showModal}
        setShowModal={setShowModal}
        onSubmitHandler={handleSubmit(onSubmitHandler)}
      />
    </>
  );
};
