import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import { FormInput, FormLabel, FormOption, FormSelect } from "../../ui";
import useCurrentLocation from "../../utils/hooks/useCurrentLocation";
import { useAllStores } from "../Menus/hooks/useAllStores";

interface Props {
  store?: any;
  form?: any;
  allowToCloneStoreHours?: boolean;
}
const DAYS_OF_WEEK = [
  {
    text: "Sunday",
    number: 0,
  },
  {
    text: "Monday",
    number: 1,
  },
  {
    text: "Tuesday",
    number: 2,
  },
  {
    text: "Wednesday",
    number: 3,
  },
  {
    text: "Thursday",
    number: 4,
  },
  {
    text: "Friday",
    number: 5,
  },
  {
    text: "Saturday",
    number: 6,
  },
];
export const StoreHoursForm = ({
  store,
  form,
  allowToCloneStoreHours,
}: Props) => {
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = form;

  const { data: storesData, loading: storesLoading } = useAllStores();
  const { currentLocation } = useCurrentLocation();
  const transformedHours = useMemo(() => {
    if (!Array.isArray(currentLocation?.working_hours)) {
      return {};
    }
    return currentLocation.working_hours.reduce(
      (prev: any, curr: any) => ({
        ...prev,
        [curr.day_of_week.toString()]: {
          id: curr.id,
          start_time: curr.hours[0].start_time,
          end_time: curr.hours[0].end_time,
        },
      }),
      {},
    );
  }, [store]);

  const onChangeStoreHoursCopy = (e: any) => {
    const storeHoursToDuplicate = storesData.all_stores.find(
      (store: any, index: number) => store.id === parseInt(e.target.value),
    );
    DAYS_OF_WEEK.forEach((item: any, index: number) => {
      setValue(
        `store_hours[${item.number}].id`,
        storeHoursToDuplicate.store_hours[index].id,
      );
      setValue(
        `store_hours[${item.number}].day_of_week`,
        storeHoursToDuplicate.store_hours[index].day_of_week,
      );
      setValue(
        `store_hours[${item.number}].hours[0].start_time`,
        storeHoursToDuplicate.store_hours[index].hours[0].start_time,
      );
      setValue(
        `store_hours[${item.number}].hours[0].end_time`,
        storeHoursToDuplicate.store_hours[index].hours[0].end_time,
      );
    });
  };

  return (
    <>
      <div>
        <div className="flex items-center w-full justify-center mb-5 shadow">
          <p className="text-lg font-semibold mr-5">Store Hours</p>
          {/* This is type="submit" by default so change the type to button to prevent submitting form */}
        </div>
        {allowToCloneStoreHours && (
          <div className="max-w-sm my-5">
            <FormLabel title="Copy Hours From" htmlFor="other_store_hours" />
            <FormSelect value={undefined} onChange={onChangeStoreHoursCopy}>
              {storesData?.all_stores
                .filter(
                  (item: any, index: number) =>
                    item?.location?.id === currentLocation?.id,
                )
                .map((item: any, index: any) => {
                  return (
                    <FormOption
                      key={item.id}
                      title={`${item.brand.name}`}
                      value={item.id}
                    />
                  );
                })}
            </FormSelect>
          </div>
        )}
        <p className="text-red-800">
          {errors?.store_hours && "Store Hours are required"}
        </p>
        <div className={`grid grid-cols-3 items-center gap-6 mb-6`}>
          <div className="text-md col-start-2 text-center">Open</div>
          <div className="self-center text-md text-center">Close</div>
          {DAYS_OF_WEEK.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <p className="text-lg">{item.text}</p>
              <input
                type="hidden"
                {...register(`store_hours[${item.number}].id`)}
                value={transformedHours[item.number.toString()]?.id}
              />
              <input
                type="hidden"
                {...register(`store_hours[${item.number}].day_of_week`)}
                value={item.number}
              />
              <div>
                <Controller
                  name={`store_hours[${item.number}].hours[0].start_time`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    transformedHours[item.number.toString()]?.start_time || ""
                  }
                  render={({ field }) => <FormInput type="time" {...field} />}
                />
              </div>
              <div>
                <Controller
                  name={`store_hours[${item.number}].hours[0].end_time`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    transformedHours[item.number.toString()]?.end_time || ""
                  }
                  render={({ field }) => <FormInput type="time" {...field} />}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
